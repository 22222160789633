import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Helmet from 'react-helmet'
import he from 'he'

import BrandBanner from '../components/brand-banner'
import MediaBanner from '../components/media-banner'
import SplitScreen from '../components/split-screen'
import Bar from '../components/archive/bar'
import Video from '../components/archive/video'
import Tile from '../components/archive/tile'
import TextItem from '../components/archive/text-item'
import VideoPopup from '../components/video-popup'

class IndexPage extends Component {
  constructor(props) {
    super(props)

    const home = this.props.data.wordpressPage

    this.state = {
      lhs: home.acf.main_image[Math.floor(Math.random() * home.acf.main_image.length)]
    }
  }

  render() {
    const home = this.props.data.wordpressPage
    const { lhs } = this.state

    const splitScreen = {
      homepage: true,
      lhs: lhs.image && lhs.image.url,
      rhs: home.acf.rhs_articles,
      categories: this.props.data.allWordpressCategory.edges,
      top_articles: home.acf.top_articles || this.props.data.allWordpressPost.edges,
    }

    return (
      <>
        <Helmet>
          <body className={`page--home ${lhs.image_is_dark && 'header--lhs-dark'}`} />
          <title>{ he.decode(home.yoast_meta.yoast_wpseo_title) }</title>
          <meta name="description" content={home.yoast_meta.yoast_wpseo_metadesc} />
          <meta property="og:title" content={he.decode(home.yoast_meta.yoast_wpseo_title)} />
          <meta property="og:description" content={home.yoast_meta.yoast_wpseo_metadesc} />
          <meta property="twitter:title" content={ home.yoast_meta.yoast_wpseo_title } />
          <meta property="twitter:description" content={ home.yoast_meta.yoast_wpseo_metadesc } />
        </Helmet>
        <SplitScreen {...splitScreen} key={splitScreen.lhs} />
        <div className='archive__wrapper'>
          <Bar {...home.acf.fold_article} />
        </div>
        { home.acf.video_section && <Video {...home.acf.video_section} /> }
        <div className='archive__collection'>
          { home.acf.tile_articles && home.acf.tile_articles.map((el, i) => {
            return (
              <Fade bottom distance='40px' key={i}>
                <Tile {...el} />
              </Fade>
            )
          }) }
        </div>
        <div className='archive__text-item__container'>
          <div className='archive__text-item__inner'>
            { home.acf.title_articles && home.acf.title_articles.map((el, i) => {
              return (
                <TextItem {...el} key={i} />
              )
            }) }
          </div>
        </div>
        <VideoPopup />
        <MediaBanner />
        <BrandBanner />
      </>
    )
  }
}

export default IndexPage

export const homeQuery = graphql`
  query homeQuery {
    allWordpressCategory {
      edges {
        node {
          name
          slug
        }
      }
    }

    wordpressPage(title: {eq: "Home"}) {
      title
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        main_image {
          image {
            url
            alt
          }
          image_is_dark
        }
        rhs_articles {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            featured_image {
              url
              alt
            }
            content_blocks {
              content
            }
          }
        }
        fold_article {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            featured_image {
              url
              alt
            }
          }
        }
        video_section {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            video
            featured_image {
              url
              alt
            }
          }
        }
        tile_articles {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            featured_image {
              url
              alt
            }
            content_blocks {
              content
            }
          }
        }
        title_articles {
          post_title
          post_name
          category
          acf {
            excerpt
            sponsored
            author {
              post_title
              post_name
            }
            type
            featured_image {
              url
              alt
            }
          }
        }
        top_articles {
          post_title
          post_name
        }
      }
    }

    allWordpressPost(sort: {fields: [acf___views], order: DESC}, limit: 7) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
