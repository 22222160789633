import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import Video from './archive/video'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal active" : "modal";
  return (
    <div className={showHideClassName}>
      <section className="modal__wrapper">
        {children}
      </section>
      <button className="modal__close" onClick={handleClose} />
    </div>
  )
}

function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + (days*24*60*60*1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '')  + expires + '; path=/'
}
function getCookie(name) {
  let nameEQ = name + '='
  let ca = document.cookie.split(';')
  for (let i=0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1,c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
  }
  return false
}

class VideoPopup extends Component {

  state = {
    show: false,
    result: '',
    msg: '',
    show: false
  }

  showModal = () => {
    this.setState({ showVideo: true });
  }

  hideModal = () => {
    this.setState({ 
      showVideo: false,
      show: false 
    });
  }

  hidePopup = () => {
    this.setState({ show: false });
  }

  componentDidMount() {
    if (getCookie('video-popup')) return

    setTimeout(() => {
      this.setState({ show: true }, () => {
        setCookie('video-popup', 'done', 365)
      })
    }, 4000);
  }

  render() {
    let { data } = this.props
    let { show, showVideo } = this.state
    const settings = data.allWordpressAcfOptions.edges[0].node.options
    const bgImage = settings.video_popup.image.url
    return (
      <>
        <div className={ show ? 'popup popup--video active' : 'popup popup--video'}>
          <div className="popup__wrapper">
            <div className="popup__bg" style={{ backgroundImage: `url(${bgImage})`}} onClick={this.showModal}>
              <h4 className='popup__tag'>Watch the video</h4>
              <div className='archive__video__button'></div>
            </div>
            <div className="popup__content">
              <h4>{settings.video_popup.title}</h4>
              <div className='popup__right'>
                <div dangerouslySetInnerHTML={{ __html: settings.video_popup.content }} />
                <Link className='btn' to={settings.video_popup.button.link}>{settings.video_popup.button.text}</Link>
                <span className="popup__close" onClick={this.hidePopup}>Next Time</span>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showVideo} handleClose={this.hideModal}>
          <div className="video-wrapper" dangerouslySetInnerHTML={{__html: this.state.showVideo && settings.video_popup.video }} />
        </Modal>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                video_popup {
                  image {
                    url
                    alt
                  }
                  title
                  content
                  button {
                    link
                    text
                  }
                  video
                }
              }
            }
          }
        }
      }
    `}
    render={data => <VideoPopup data={data} {...props} />}
  />
)
