import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"

class MediaBanner extends Component {
  render() {
    const { as_seen } = this.props.data.wordpressAcfOptions.options

    return (
      <div className='media-banner'>
        <h5>As seen on</h5>
        <div className='media-banner__images'>
          { as_seen && as_seen.map((el, i) => (
            <picture key={i}>
              <img src={el.image.url} alt={el.title} />
            </picture>
          )) }
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            as_seen {
              image {
                url
              }
              title
            }
          }
        }
      }
    `}
    render={data => <MediaBanner data={data} {...props} />}
  />
)
